/* Change this file to get your personal Portfolio */

// Your Summary And Greeting Section

const greeting = {
  /* Your Summary And Greeting Section */
  title: "Hi all I'm Raj",
  subTitle:
    "A passionate Full Stack Software Engineer 🚀 having an experience of building Web and Mobile apps with JavaScript / Reactjs / Nodejs / Java and some other cool libraries and frameworks",
  resumeLink:
    "https://drive.google.com/open?id=1WN__mjAPnQfJMYzYRF9DZLfWbuvMKYh2",
};

// Your Social Media Link

const socialMediaLinks = {
  github: "https://github.com/saraogiraj94",
  linkedin: "https://www.linkedin.com/in/raj-saraogi-568a76129/",
  gmail: "saraogi.raj40@gmail.com",
  facebook: "https://www.facebook.com/raj.vandersaraogi",
  stackoverflow: "https://stackoverflow.com/users/6483580/raj-saraogi",
  quora: "https://www.quora.com/profile/Raj-Saraogi",
};

// Your Skills Section

const skillsSection = {
  title: "What i do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANT TO EXPLORE EVERY TECH STACK",
  skills: [
    "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications",
    "⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks",
    "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean",
  ],

  /* Make Sure You include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5",
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt",
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass",
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js",
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react",
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node",
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift",
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm",
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database",
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws",
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire",
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python",
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker",
    },
  ],
};

//Skills what i do
const skills = {
  data: [
    {
      title: "Full Stack Development",
      imagePath: "fullstack.svg",
      skills: [
        "⚡ Building resposive website front end using React-Redux",
        "⚡ Experience in micro services, with backend development in Spring Boot and Nodejs",
        "⚡ Working with NoSQL and SQL databases like MongoDB, MySQL, MariaDB",
        "⚡ End to end experience in project development, i.e from choosing tech stack -> development -> deployment",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "fab fa-html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fab fa-css3-alt",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "fab fa-sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "fab fa-js",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "fab fa-react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "fab fa-node",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "fab fa-npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "fab fa-gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "fab fa-java",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "fab fa-android",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      imagePath: "cloud_infrastructure.svg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on dockers and integration of databases",
        "⚡ Experience with Architectural patterns like event sourcing and CQRS with KAFKA.",
        "⚡ Worked with various AWS services like SQS, SNS, ECS, ECR, Cloud Watch e.t.c.",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
  ],
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%", //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%",
    },
    {
      Stack: "Programming",
      progressPercentage: "60%",
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: "Your Github Converted Token",
  githubUserName: "Your Github Username",
};

// Some Big Projects You have worked with your company

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELP TO CREATE THEIR TECH",
  projects: [
    {
      image: "https://drive.google.com/uc?id=1exWn9T6j8TsfDDHJnS3VR66eP6RiGAfY",
      link: "http://saayahealth.com/",
    },
    {
      image: "https://drive.google.com/uc?id=1MXoXcQRK-pH8J82wyjCW4SJk5AxJe7tf",
      link: "http://nextu.se/",
    },
  ],
};

// Your Achievement Section Include Your Certification Talks and More

const achievementSection = {
  title: "Achievements And Certifications 🏆 ",
  subtitle:
    "Achievements, Certifications Award Letters and Some Cool Stuff that i have done !",

  achivementsCards: [
    {
      title: "Google Code-In Finalist",
      description:
        "First Pakistani to be selected as Google Google-in Finalist from 4000 students from 77 diffrent countries",
      image:
        "https://1.bp.blogspot.com/-Ig-v1tDXZt4/XDODmZvWp1I/AAAAAAAAB0A/KtbFdBPFVQw2O15FekkIR0Yg8MUp--rngCLcBGAs/s1600/GCI%2B-%2BVertical%2B-%2BGray%2BText%2B-%2BWhite%2BBG.png",
      footerLink: [
        {
          name: "Certification",
          url:
            "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing",
        },
        {
          name: "Award Letter",
          url:
            "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing",
        },
        {
          name: "Google Code-in Blog",
          url:
            "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html",
        },
      ],
    },
    {
      title: "Google Assistant Action",
      description:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Google_Assistant_logo.svg/1200px-Google_Assistant_logo.svg.png",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url:
            "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en",
        },
      ],
    },

    {
      title: "PWA Web App Developer",
      description:
        "Completed Certifcation from SMIT for PWA Web App Development",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu93Bd6LDbxPEOXr-hfLqLYzmHny8c0MJoI3exQP-lwpFLRT7g&s",
      footerLink: [
        { name: "Certification", url: "" },
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/",
        },
      ],
    },
  ],
};

// Blogs Section

const blogSection = {

};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅",

};

// Podcast Section

const podcastSection = {
  title: "Podcast 🎙️",
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
};

const contactInfo = {
  title: "Contact Me ☎️",
  subtitle: "Discuss a project or just want to say hi my inbox is open for all",
  number: "+91-8460167576",
  email_address: "saraogi.raj40@gmail.com",
};

//Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
};

const certifications = {
  certifications: [
    {
      title: "Reactive Architecture: CQRS and Event Sourcing.",
      subtitle: "- Architectural Patterns",
      logo_path: "imb.jpg",
      certificate_link:
        "https://courses.cognitiveclass.ai/certificates/7faa51c94c7f4d2d9941c6f13984d339",
      alt_name: "IBM",
      color_code: "#f6f6f6",
    },
    {
      title: "M101P: MongoDB for Developers",
      subtitle: "- NoSQL MongoDB",
      logo_path: "mongo.png",
      certificate_link:
        "https://university.mongodb.com/course_completion/eee362fc-ee6f-4858-b23d-56b6a61b",
      alt_name: "MongoDB",
      color_code: "#f6f6f6",
    },
    {
      title: "Modern React With Redux",
      subtitle: "- Udemy",
      logo_path: "udemy.png",
      certificate_link:
        "https://udemy-certificate.s3.amazonaws.com/pdf/UC-A67VFI5Q.pdf",
      alt_name: "IBM",
      color_code: "#f6f6f6",
    },
    {
      title: "Docker Essentials: A Developer Introduction",
      subtitle: "- Cloud",
      logo_path: "imb.jpg",
      certificate_link:
        "https://courses.cognitiveclass.ai/certificates/c9ae6f952c614dd7af25b3e95263e155",
      alt_name: "IBM",
      color_code: "#f6f6f6",
    },
    {
      title: "Intro to Python for Data Science Course",
      subtitle: "- Datacamp",
      logo_path: "datacamp.png",
      certificate_link:
        "https://udemy-certificate.s3.amazonaws.com/pdf/UC-A67VFI5Q.pdf",
      alt_name: "IBM",
      color_code: "#f6f6f6",
    },
    {
      title: " Apache Kafka Series - Learn Apache Kafka for Beginners v2 ",
      subtitle: "- Udemy",
      logo_path: "udemy.png",
      certificate_link:
        "https://udemy-certificate.s3.amazonaws.com/pdf/UC-H4XGDHCO.pdf",
      alt_name: "Udemy",
      color_code: "#f6f6f6",
    },
    {
      title: "IBM Blockchain Essentials",
      subtitle: "- Blockchain",
      logo_path: "imb.jpg",
      certificate_link:
        "https://www.youracclaim.com/badges/519fc66e-cf85-4300-916a-ee235297f944/public_url",
      alt_name: "IBM",
      color_code: "#f6f6f6",
    },
    {
      title: "Python Training",
      subtitle: "- Internshala",
      logo_path: "internshala.png",
      certificate_link:
        "https://drive.google.com/file/d/0B3qHw5RdegE3LWNMU01wemhWX3M/view",
      alt_name: "IBM",
      color_code: "#f6f6f6",
    },
  ],
};

const competitiveSites = {
  competitiveSites: [],
};
const degrees = {
  degrees: [
    {
      title: "LDRP Institute of Technology & Research",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "ibm_logo.png",
      alt_name: "LDRP-ITR",
      duration: "2014-18",
      descriptions: [
        "⚡ I have studied core software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Consistently performing well in my undergrads, I had an overall CPI of 8.64.",
        "⚡ I was vice chair to IEEE Student Branch and member of placement committee.",
      ],
      website_link: "https://www.ldrp.ac.in/",
    },
  ],
};
export {
  greeting,
  socialMediaLinks,
  skillsSection,
  techStack,
  skills,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  certifications,
  competitiveSites,
  degrees,
};
