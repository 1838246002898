import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Flip, Fade } from "react-reveal";

class SkillSection extends Component {
  render() {
    return (
      <div>
        {skills.data.map((skill, index) => {
          return (
            <div>
              {(index + 1) % 2 === 0 ? (
                <div className="skills-main-div">
                  <Flip left duration={2000}>
                    <div className="skills-image-div">
                      <img
                        alt="Raj Saraogi is Analysing Data"
                        src={require(`../../assests/images/${skill.imagePath}`)}
                      ></img>
                    </div>
                  </Flip>

                  <div className="skills-text-div">
                    <Fade right duration={1000}>
                      <h1 className="skills-heading">{skill.title}</h1>
                    </Fade>
                    <Fade right duration={1500}>
                      <SoftwareSkill logos={skill.softwareSkills} />
                    </Fade>
                    <Fade right duration={2000}>
                      <div>
                        {skill.skills.map((skillSentence) => {
                          return (
                            <p className="subTitle skills-text">
                              {skillSentence}
                            </p>
                          );
                        })}
                      </div>
                    </Fade>
                  </div>
                </div>
              ) : (
                <div className="skills-main-div">
                  <div className="skills-text-div">
                    <Fade right duration={1000}>
                      <h1 className="skills-heading">{skill.title}</h1>
                    </Fade>
                    <Fade right duration={1500}>
                      <SoftwareSkill logos={skill.softwareSkills} />
                    </Fade>
                    <Fade right duration={2000}>
                      <div>
                        {skill.skills.map((skillSentence) => {
                          return (
                            <p className="subTitle skills-text">
                              {skillSentence}
                            </p>
                          );
                        })}
                      </div>
                    </Fade>
                  </div>
                  <Flip left duration={2000}>
                    <div className="skills-image-div">
                      <img
                        alt="Raj Saraogi is Analysing Data"
                        src={require(`../../assests/images/${skill.imagePath}`)}
                      ></img>
                    </div>
                  </Flip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default SkillSection;
