import React, { Component } from "react";
import "./DegreeCard.css";
import { Fade, Flip } from "react-reveal";

class DegreeCard extends Component {
  render() {
    const degree = this.props.degree;
    return (
      <div className="degree-card">
        <Fade right duration={2000} distance="40px">
          <div className="card-body">
            <div style={{display:"flex",background:"#f5f5f5"}}>
              <img
                src={require("../../assests/images/education.svg")}
                style={{ width: "10%" }}
              ></img>
              <div className="body-header">
                <div className="body-header-title">
                  <h2 className="card-title">{degree.title}</h2>
                  <h3 className="card-subtitle">{degree.subtitle}</h3>
                </div>
                <div className="body-header-duration">
                  <h3 className="duration">{degree.duration}</h3>
                </div>
              </div>
            </div>
            <div classname="body-content">
              {degree.descriptions.map((sentence) => {
                return <p className="content-list">{sentence}</p>;
              })}
              <a href={degree.website_link}>
                <div className="visit-btn">
                  <p className="btn">Visit Website</p>
                </div>
              </a>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

export default DegreeCard;
